.at-dashboard-block {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
          box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
  margin-top: 20px;
  min-height: 384px;
  overflow: hidden; }
  .at-dashboard-block--join-us {
    background: url("/images/dashboard/join-us.png") no-repeat;
    background-size: cover; }
  .at-dashboard-block--offer {
    background-color: #ffb822; }
  .at-dashboard-block--info-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center; }
    .at-dashboard-block--info-center i {
      font-size: 40px; }
    .at-dashboard-block--info-center p {
      font-size: 22px;
      line-height: 1.18;
      padding: 0 50px; }
  .at-dashboard-block a:hover {
    color: #fff; }

.at-dashboard-title {
  color: #000;
  display: block;
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  width: 100%; }

.at-news-header {
  background-position: center center;
  background-size: cover;
  height: 224px;
  overflow: hidden;
  position: relative; }

.at-news-title,
.at-post-title {
  background-color: #bd0b0b;
  bottom: 20px;
  color: #fff;
  font-size: 19px;
  font-weight: 600;
  left: 2px;
  padding: 5px;
  position: absolute;
  right: 2px; }

.bg-red {
  color: #fff;
  background-color: #bd0b0b; }

.bg-gray, .at-news-post-internet {
  color: #fff;
  background-color: #868e96; }

.bg-yellow, .at-news-post-category {
  color: #fff;
  background-color: #f5a04d; }

.bg-blue, .bg-bleu, .at-news-post-testimony {
  color: #fff;
  background-color: #144475; }

.at-news-label {
  left: 14px;
  position: absolute;
  top: 14px; }

.at-news-gradient {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(2%, rgba(238, 238, 238, 0)), color-stop(99%, #000));
  background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0) 2%, #000 99%);
  bottom: 0;
  height: 100px;
  left: 0;
  position: absolute;
  width: 100%; }

.at-news-description {
  height: 60px;
  margin: 20px;
  overflow: hidden;
  background-color: initial; }

.at-posts-header {
  border-bottom: 1px solid #ebedf2;
  color: #373a3c;
  font-size: 19px;
  font-weight: 500;
  padding: 25px; }

.at-posts-body {
  padding: 30px; }

.m-brand__logo-wrapper img {
  display: block;
  margin: auto;
  width: 300px; }

.m-login__logo img {
  max-width: 300px; }

.at-exercise-header {
  background-color: #faf9fc; }
  .at-exercise-header > div {
    padding: 21px 15px; }

.at-exercise-type {
  font-weight: 500; }
  .at-exercise-type--indigo {
    color: #6610f2; }
  .at-exercise-type--blue {
    color: #007bff; }
  .at-exercise-type--red {
    color: #dc3545; }
  .at-exercise-type--orange {
    color: #f5a04d; }
  .at-exercise-type--cyan {
    color: #17a2b8; }
  .at-exercise-type--green {
    color: #28a745; }

.at-exercise-list-title {
  font-size: 16px;
  font-weight: 500; }

.at-details-group {
  border: solid 4px #ebedf2;
  padding: 10px; }

.at-details-group-columns {
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2; }

.at-exercise-img-thumb {
  margin: 5px;
  width: 60px; }

.at-exercise-list-img-thumb {
  border-radius: 4px;
  display: block;
  margin: 10px auto;
  max-height: 75px;
  max-width: 95px; }

.at-validate-exercise-checkbox {
  margin: -3px 0 0 9px; }

.at-exercise-card {
  background-color: #fff;
  border: 1px solid rgba(69, 65, 78, 0.1);
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
          box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-top: 20px;
  position: relative; }

.at-exercise-card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 12px 20px 0; }
  .at-exercise-card-header:hover {
    cursor: move; }

.anchor-exercice {
  display: block;
  height: 100px;
  margin-top: -100px;
  visibility: hidden; }
  @media (max-width: 992px) {
    .anchor-exercice {
      height: 160px;
      margin-top: -160px; } }

.at-exercise-card-image {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  max-height: 200px;
  max-width: 170px;
  overflow: hidden;
  width: 30%; }
  .at-exercise-card-image img {
    max-height: 100%;
    max-width: 100%; }

.at-exercise-card-info {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding-left: 12px; }

.at-exercise-card-title {
  bottom: 10px;
  color: #000;
  font-size: 19px;
  font-weight: 600;
  left: 10px;
  text-shadow: #fff 0 0 2px; }

.at-exercise-card-body {
  padding: 20px 20px 70px; }

.at-exercise-bottom-buttons {
  bottom: 0;
  padding: 20px;
  position: absolute;
  width: 100%; }

.at-exercise-card-labels {
  padding: 12px 20px 0; }

.word-wrap-break {
  word-wrap: break-word; }

.at-badge {
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  text-align: center; }
  .at-badge.m-badge--wide {
    border-radius: 13px;
    padding: 3px 10px; }
  .at-badge--valid {
    background-color: #d6f2ed;
    color: #34bfa3; }
  .at-badge--invalid {
    background-color: #fff1d3;
    color: #ffb822; }
  .at-badge--post-draft {
    background-color: #ffc107; }
  .at-badge--post-category {
    background-color: #bd0b0b; }
  .at-badge--info {
    background-color: #36a3f7; }
  .at-badge--green {
    background-color: #28a745; }
  .at-badge--indigo {
    background-color: #6610f2; }
  .at-badge--pink {
    background-color: #e83e8c; }
  .at-badge--red {
    background-color: #dc3545; }
  .at-badge--orange {
    background-color: #f5a04d; }
  .at-badge--purple {
    background-color: #6f42c1; }
  .at-badge--yellow {
    background-color: #ffc107; }
  .at-badge--cyan {
    background-color: #17a2b8; }
  .at-badge--teal {
    background-color: #20c997; }
  .at-badge--dark {
    background-color: #373a3c; }
  .at-badge--large {
    border-radius: 100%;
    font-size: 19px;
    font-weight: bold;
    height: 80px;
    line-height: 80px;
    width: 80px; }
  .at-badge--responsive {
    border-radius: 20px;
    font-size: 19px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    width: auto; }
  .at-badge.result-green {
    background-color: #34bfa3;
    color: #fff; }
  .at-badge.result-orange {
    background-color: #ffb822;
    color: #fff; }
  .at-badge.result-red {
    background-color: #bd0b0b;
    color: #fff; }
  .at-badge--center {
    display: block;
    margin: auto; }

.at-label {
  display: block;
  font-weight: 500; }
  .at-label:not(:first-child) {
    margin-top: 10px; }
  .at-label--grey {
    color: #868e96; }
  .at-label--dark {
    color: #373a3c; }

.at-btn--rounded {
  border-radius: 11px;
  font-size: 10px;
  padding: 4px 10px; }

.at-btn--light {
  background-color: #fff;
  border: solid 1px #868e96;
  color: #868e96; }
  .at-btn--light:active, .at-btn--light:hover, .at-btn--light:focus, .at-btn--light:visited {
    background-color: #fff;
    border: solid 1px #868e96;
    color: #868e96; }

.at-btn--grey {
  background-color: #868e96;
  color: #fff; }
  .at-btn--grey:active, .at-btn--grey:hover, .at-btn--grey:focus, .at-btn--grey:visited {
    background-color: #868e96;
    color: #fff; }

.at-btn--dark {
  background-color: #343a40;
  color: #fff; }
  .at-btn--dark:active, .at-btn--dark:hover, .at-btn--dark:focus, .at-btn--dark:visited {
    background-color: #343a40;
    color: #fff; }

.at-btn--primary {
  background-color: #bd0b0b;
  border: 0;
  color: #fff; }
  .at-btn--primary:active, .at-btn--primary:hover, .at-btn--primary:focus, .at-btn--primary:visited {
    background-color: #bd0b0b;
    border: 0;
    color: #fff; }

.at-btn--gray-link {
  border: 0;
  color: #868e96; }
  .at-btn--gray-link:active, .at-btn--gray-link:hover, .at-btn--gray-link:focus, .at-btn--gray-link:visited {
    border: 0;
    color: #868e96; }

.at-btn--yellow-link {
  border: 0;
  color: #ffc107; }
  .at-btn--yellow-link:active, .at-btn--yellow-link:hover, .at-btn--yellow-link:focus, .at-btn--yellow-link:visited {
    border: 0;
    color: #ffc107; }

.at-btn--primary-link {
  border: 0;
  color: #bd0b0b; }
  .at-btn--primary-link:active, .at-btn--primary-link:hover, .at-btn--primary-link:focus, .at-btn--primary-link:visited {
    border: 0;
    color: #bd0b0b; }

.at-btn--tall {
  padding: 10px; }

.at-btn-icon--white {
  color: #fff; }
  .at-btn-icon--white:hover {
    color: #bd0b0b; }

.at-filter-input {
  background-color: #f4f5f8; }

.at-subheader {
  margin-bottom: 30px; }
  .at-subheader-title {
    display: inline-block;
    margin: 0;
    padding: 7px 20px 7px 0;
    vertical-align: middle; }

.at-subheader-breadcrumbs {
  padding-left: 20px; }

.at-portlet {
  border-radius: 4px;
  min-height: 85vh; }
  .at-portlet .m-portlet__body {
    padding: 50px 30px 30px; }
  .at-portlet .m-portlet--body-separator {
    height: 0;
    border-bottom: dashed 1px #979797; }
  .at-portlet > :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .at-portlet--small {
    min-height: 0; }
  .at-portlet .at-portlet-body--session {
    padding-top: 0; }
  .at-portlet .at-portlet-header .action {
    color: #fff;
    display: inline-block;
    font-size: 1rem;
    font-weight: normal;
    line-height: 2.77rem; }
    .at-portlet .at-portlet-header .action i {
      vertical-align: middle; }

.at-portlet-header-admin {
  padding: 17px 30px;
  background-color: #55AC5C; }

.at-portlet-header {
  background-color: #bd0b0b;
  padding: 17px 30px; }
  .at-portlet-header .at-btn--primary {
    background-color: #fff;
    border: 0;
    color: #bd0b0b; }
    .at-portlet-header .at-btn--primary:active, .at-portlet-header .at-btn--primary:hover, .at-portlet-header .at-btn--primary:focus, .at-portlet-header .at-btn--primary:visited {
      background-color: #fff;
      border: 0;
      color: #bd0b0b; }
  .at-portlet-header--no-padding {
    padding: 0 30px; }
  .at-portlet-header--white {
    background-color: #fff;
    border-bottom: 1px solid #ebedf2; }
  .at-portlet-header--gray {
    background-color: #f4f5f8;
    border-bottom: 1px solid #ebedf2;
    padding: 1rem 0px; }
    .at-portlet-header--gray a {
      margin: inherit;
      font-weight: bold;
      text-decoration: underline; }
  .at-portlet-header .nav.nav-tabs {
    border-bottom: 0;
    margin-bottom: 0; }
    .at-portlet-header .nav.nav-tabs .nav-item {
      margin: 0; }
      .at-portlet-header .nav.nav-tabs .nav-item .nav-link,
      .at-portlet-header .nav.nav-tabs .nav-item .nav-link.active,
      .at-portlet-header .nav.nav-tabs .nav-item .nav-link:hover,
      .at-portlet-header .nav.nav-tabs .nav-item .nav-link:focus {
        line-height: 30px;
        border: 0;
        border-radius: 0;
        color: #fff;
        padding: 20px 16px; }
        .at-portlet-header .nav.nav-tabs .nav-item .nav-link.active, .at-portlet-header .nav.nav-tabs .nav-item .nav-link:hover, .at-portlet-header .nav.nav-tabs .nav-item .nav-link:focus,
        .at-portlet-header .nav.nav-tabs .nav-item .nav-link.active.active,
        .at-portlet-header .nav.nav-tabs .nav-item .nav-link.active:hover,
        .at-portlet-header .nav.nav-tabs .nav-item .nav-link.active:focus,
        .at-portlet-header .nav.nav-tabs .nav-item .nav-link:hover.active,
        .at-portlet-header .nav.nav-tabs .nav-item .nav-link:hover:hover,
        .at-portlet-header .nav.nav-tabs .nav-item .nav-link:hover:focus,
        .at-portlet-header .nav.nav-tabs .nav-item .nav-link:focus.active,
        .at-portlet-header .nav.nav-tabs .nav-item .nav-link:focus:hover,
        .at-portlet-header .nav.nav-tabs .nav-item .nav-link:focus:focus {
          background-color: rgba(255, 255, 255, 0.15); }

.at-portlet-title {
  color: #fff;
  font-size: 19px;
  font-weight: 600;
  margin-right: 15px;
  margin-top: 8px; }
  .at-portlet-title--dark {
    color: #373a3c; }

.at-modal-title-icon {
  font-size: 25px;
  margin-right: 10px; }
  .at-modal-title-icon--primary {
    color: #bd0b0b; }

.at-dropzone {
  padding: 60px 20px; }

.sticky-header {
  z-index: 1;
  position: sticky;
  top: 0; }

select.select2 {
  max-height: 34px;
  opacity: 0; }

.title-1 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 30px; }
  .title-1:not(:first-child) {
    margin-top: 30px; }

.title-2 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px; }

.at-table--head-bg-secondary thead th {
  background-color: #5d5d5d;
  color: #fff;
  font-weight: bold; }

.result-badge {
  border-radius: 11px;
  display: inline-block;
  font-size: 10px;
  font-weight: normal;
  line-height: 22px;
  padding: 0 9px; }
  .result-badge.result-green {
    background-color: #d6f2ed;
    color: #34bfa3; }
  .result-badge.result-orange {
    background-color: #fff1d3;
    color: #ffb822; }
  .result-badge.result-red {
    background-color: #f0ced2;
    color: #bd0b0b; }

.test-global-result {
  background-color: #20c997;
  color: #fff;
  font-weight: bold;
  padding: 30px 30px 50px; }
  .test-global-result .title {
    font-size: 19px;
    margin-bottom: 30px; }
    .test-global-result .title .icon {
      font-size: 24px;
      margin-right: 14px;
      vertical-align: bottom; }
  .test-global-result .titled-badge .badge-title {
    font-size: 22px;
    margin-bottom: 40px; }
  .test-global-result .titled-badge .at-badge {
    background-color: #fff; }
  .test-global-result.result-green {
    background-color: #20c997; }
    .test-global-result.result-green .titled-badge .at-badge {
      color: #20c997; }
  .test-global-result.result-orange {
    background-color: #ffb822; }
    .test-global-result.result-orange .titled-badge .at-badge {
      color: #ffb822; }
  .test-global-result.result-red {
    background-color: #bd0b0b; }
    .test-global-result.result-red .titled-badge .at-badge {
      color: #bd0b0b; }

.entity-field {
  margin-bottom: 20px; }
  .entity-field--title {
    color: #868e96; }
  .entity-field--value {
    color: #373a3c; }

.at-pdf-viewer {
  max-height: 75vh;
  overflow-y: scroll; }

.at-search-label {
  margin-bottom: 0 !important; }

.m-dropdown .m-dropdown__wrapper.at-planning-dropdown {
  width: 310px; }

.m-dropdown > .m-portlet__nav-link {
  color: #fff;
  font-weight: bold; }
  .m-dropdown > .m-portlet__nav-link i {
    margin-left: 5px;
    font-size: 1rem;
    font-weight: bold; }

.header-right {
  float: right;
  font-weight: bold; }

.test-total--row {
  border-bottom: dashed 1px #d1d1d1;
  padding: 27px 0 17px 0; }

.test-total--label {
  color: #373a3c;
  font-size: 1.23rem;
  font-weight: bold;
  line-height: 20px; }

.test-total--value {
  color: #36a3f7;
  font-size: 1.69rem;
  font-weight: bold;
  line-height: 20px;
  text-align: right; }
  .test-total--value.result-ok {
    color: #20c997; }

.sessions-count {
  margin-top: 40px; }

.toggleable .toggleable-item {
  display: none; }
  .toggleable .toggleable-item.active {
    display: block; }

.at-brand--skin-gray {
  background-color: #868e96; }
  .at-brand--skin-gray .m-brand__toggler--left span {
    background-color: #fff; }
    .at-brand--skin-gray .m-brand__toggler--left span:after, .at-brand--skin-gray .m-brand__toggler--left span:before {
      background-color: white; }

.at-aside-left-close--skin-gray {
  background-color: transparent;
  border: 1px solid #fff; }
  .at-aside-left-close--skin-gray i {
    color: #fff; }

.m-aside-menu--skin-gray .m-menu__ver-arrow {
  color: #fff; }

.m-aside-menu--skin-gray .m-menu__item--active .m-menu__ver-arrow {
  color: #bd0b0b; }

:not(.m-menu__item--hover) > .m-menu__submenu {
  display: none; }

@media (min-width: 993px) {
  .m-grid--desktop {
    position: relative; }
    .m-grid--desktop #m_aside_left {
      position: absolute; } }

@media (max-width: 992px) {
  .m-grid__item--fluid {
    padding-top: 60px; }
  .m-menu__nav .m-menu__item > .m-menu__link .at-user-avatar + .m-menu__link-text {
    padding-left: 50px; }
  .m-menu__submenu {
    display: block; }
  .m-menu__subnav a {
    color: #fff; }
  .m-menu__subnav .m-menu__item--active a {
    color: #bd0b0b;
    background-color: #ebedf2;
    border-radius: 4px; }
  .sticky-header {
    top: 60px; } }

.logo-wrapper-div {
  background: #fff;
  padding: 15px 0; }

#m_header .m-brand__logo-wrapper img {
  height: 60px;
  width: auto; }

.pdf-preview {
  margin: 0 auto;
  min-width: 860px;
  min-height: 700px; }
  .pdf-preview iframe {
    width: 100%;
    height: 100%; }

.alert.m-alert--outline.alert-danger.alert-dismissible .close {
  padding-right: 5px; }

.toupper {
  text-transform: uppercase; }

.m-brand__logo-modal img {
  display: block;
  margin: auto;
  width: 180px; }

.m-login {
  background-image: url("/admin/dist/demo3/assets/app/media/img//bg/bg-1.jpg"); }

.m-header--fixed .m-body {
  padding-top: 0 !important; }

.m-aside-left--fixed.m-header--static .m-aside-left, .m-aside-left--fixed.m-header--fixed .m-aside-left {
  top: 0 !important; }

.at-short-datatable > .m-datatable__table {
  min-height: 0 !important; }

.m-datatable > .m-datatable__pager > .m-datatable__pager-nav > li > .m-datatable__pager-link.m-datatable__pager-link--active, .m-datatable > .m-datatable__pager > .m-datatable__pager-nav > li > .m-datatable__pager-link.m-datatable__pager-link--first:hover, .m-datatable > .m-datatable__pager > .m-datatable__pager-nav > li > .m-datatable__pager-link.m-datatable__pager-link--prev:hover, .m-datatable > .m-datatable__pager > .m-datatable__pager-nav > li > .m-datatable__pager-link.m-datatable__pager-link--next:hover, .m-datatable > .m-datatable__pager > .m-datatable__pager-nav > li > .m-datatable__pager-link.m-datatable__pager-link--last:hover {
  background-color: #bd0b0b; }

.m-datatable > .m-datatable__pager > .m-datatable__pager-nav > li > .m-datatable__pager-link:hover {
  background-color: #bd0b0b; }

.m-datatable > .m-datatable__pager > .m-datatable__pager-info .m-datatable__pager-size .btn.dropdown-toggle:focus, .m-datatable > .m-datatable__pager > .m-datatable__pager-info .m-datatable__pager-size .btn.dropdown-toggle:hover {
  background-color: #bd0b0b; }

.m-datatable > .m-datatable__pager > .m-datatable__pager-info .m-datatable__pager-size.show > .btn.dropdown-toggle {
  background-color: #bd0b0b; }

.btn.m-btn--hover-accent:hover, .btn.m-btn--hover-accent:active, .btn.m-btn--hover-accent:focus {
  background-color: #bd0b0b !important;
  border-color: #bd0b0b !important;
  color: #fff !important; }

.show > .btn.m-btn--hover-accent.dropdown-toggle {
  background-color: #bd0b0b !important;
  border-color: #bd0b0b !important;
  color: #fff !important; }

.at-post-item {
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
          box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
  height: 290px;
  margin-bottom: 20px; }
  .at-post-item--draft img,
  .at-post-item--draft .at-post-summary > p {
    opacity: .6; }

.at-post-header {
  background-position: center center;
  background-size: cover;
  height: 150px;
  overflow: hidden;
  position: relative; }
  .at-post-header--tall {
    height: 224px; }

.at-post-details-header {
  position: relative; }

.at-post-image {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%; }

.at-post-delete-btn {
  position: absolute;
  right: 6px;
  top: 6px; }

.at-post-label {
  left: 14px;
  position: absolute;
  top: 14px; }

.at-post-summary {
  padding: 20px; }
  .at-post-summary > p {
    height: 65px;
    overflow: hidden; }

.at-post-details-image {
  width: 100%; }

.at-post-details-date {
  border-top: 1px solid #bd0b0b;
  margin: 10px 0;
  width: 130px; }

.at-post-details-summary {
  color: #868e96;
  font-style: italic;
  margin-bottom: 40px; }

.at-post-details-comments {
  margin-bottom: 50px; }

.at-post-image-preview {
  border-radius: 4px;
  display: block;
  width: 130px; }

.result-widget {
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 20px;
  max-width: 104px; }

.user-edit .custom-file .form-group {
  margin-bottom: 0; }
  .user-edit .custom-file .form-group .col-form-label, .user-edit .custom-file .form-group label {
    display: none; }
  .user-edit .custom-file .form-group .custom-file-input {
    opacity: 0; }

.exercise-title .form-control-label, .exercise-title legend {
  color: #373a3c;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  margin-bottom: 20px; }

.exercise-title .exercise-label {
  display: inline-block; }

.exercise-title .at-ajax-form-btn {
  margin-left: 10px; }

.exercise-subtitle .form-control-label,
.exercise-subtitle legend {
  color: #373a3c;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  margin-bottom: 10px; }

.form-group {
  margin-bottom: 30px; }
  .form-group .form-check .form_encadre {
    line-height: unset;
    font-size: initial; }
  .form-group label {
    color: #373a3c;
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    margin-bottom: 10px; }

.col-form-label {
  color: #373a3c;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  margin-bottom: 10px; }

.exercise-results {
  margin-bottom: 20px; }

.test-form .form-control,
.form-control--small .form-control {
  max-width: 250px; }

.qasls-form .exercise-title legend {
  font-weight: 300;
  margin-bottom: 0; }

.qasls-form .exercise-group {
  border-bottom: dashed 1px #d1d1d1;
  padding: 20px 0; }
  .qasls-form .exercise-group:nth-child(2n+1) {
    background-color: #fafafa; }

.qasls-form .exercise-subtitle {
  text-align: center; }
  @media (max-width: 992px) {
    .qasls-form .exercise-subtitle {
      text-align: left; } }
  .qasls-form .exercise-subtitle legend {
    font-weight: 300;
    margin-bottom: 0;
    padding: 0; }

.qasls-form .exercise-results {
  margin-bottom: 0;
  text-align: center; }
  @media (max-width: 992px) {
    .qasls-form .exercise-results {
      text-align: left; } }
  .qasls-form .exercise-results .form-check-input {
    position: relative; }
  .qasls-form .exercise-results .result-widget {
    margin: 0; }

.aclrsi-form .aclrsi-edge {
  margin-bottom: 20px; }

.aclrsi-form .exercise-results {
  text-align: center; }
  .aclrsi-form .exercise-results .result-widget {
    display: inline-block;
    margin: 0;
    max-width: none; }
    .aclrsi-form .exercise-results .result-widget .form-check {
      display: inline-block;
      padding: 20px 15px 0; }
      .aclrsi-form .exercise-results .result-widget .form-check input[type='radio'] {
        left: 50%;
        margin: 0;
        top: 0;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }

.sirsi-form .sirsi-edge {
  margin-bottom: 20px; }

.sirsi-form .exercise-results {
  text-align: center; }
  .sirsi-form .exercise-results .result-widget {
    display: inline-block;
    margin: 0;
    max-width: none; }
    .sirsi-form .exercise-results .result-widget .form-check {
      display: inline-block;
      padding: 20px 15px 0; }
      .sirsi-form .exercise-results .result-widget .form-check input[type='radio'] {
        left: 50%;
        margin: 0;
        top: 0;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }

.s_starts_test .subgroup-name {
  font-size: 1.25rem;
  font-weight: bold; }

.s_starts_test .tar-form .exercise {
  border-bottom: none; }

.tar-form .global-comment-title {
  padding-bottom: 20px; }

.tar-form .group-header {
  background-color: #ebedf2;
  padding: 10px 0; }

.tar-form .exercise-group {
  margin-bottom: 20px; }

.tar-form .exercise {
  border-bottom: 1px dashed #d1d1d1;
  padding: 10px 0; }

.tar-form .exercise-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 20px; }

.tar-form .group-name {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 20px 0 10px; }

.tar-form .subgroup-name {
  font-size: 1.25rem;
  font-weight: bold; }

.tar-form .result-header {
  display: none;
  float: left;
  font-weight: bold;
  width: 49%; }

.tar-form .exercise-results label {
  font-size: inherit;
  font-weight: normal;
  margin: 0; }

.tar-form .exercise-results .comment label {
  margin-bottom: 5px; }

.tar-form .exercise-results .result-widget {
  display: block;
  margin: 0 0 20px;
  max-width: none; }
  .tar-form .exercise-results .result-widget .form-check {
    display: inline-block;
    padding: 0 15px 20px;
    text-align: center;
    width: 49%; }
    .tar-form .exercise-results .result-widget .form-check input[type='radio'] {
      bottom: 0;
      left: 50%;
      margin: 0;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
  .tar-form .exercise-results .result-widget .result {
    color: #ebedf2;
    display: block;
    float: left;
    text-align: center;
    width: 50%; }
    .tar-form .exercise-results .result-widget .result i {
      font-size: 2rem; }
    .tar-form .exercise-results .result-widget .result.active.result-validated {
      color: #34bfa3; }
    .tar-form .exercise-results .result-widget .result.active.result-not-validated {
      color: #f4516c; }

@media (min-width: 768px) {
  .tar-form .exercise-title {
    font-weight: normal;
    margin-bottom: 0; }
  .tar-form .result-header {
    display: block; }
  .tar-form .exercise-results {
    margin-bottom: 0; }
    .tar-form .exercise-results label {
      display: none; }
    .tar-form .exercise-results .result-widget {
      margin: 0; }
      .tar-form .exercise-results .result-widget .form-check {
        padding: 20px 0 0;
        text-align: center;
        width: 49%; } }

.aclrsi-test--link {
  font-size: 1rem;
  font-weight: normal;
  margin-left: 10px; }

.collection_holder .form-group :not(fieldset) {
  margin-bottom: 0; }

.collection_holder > .form-group {
  margin-bottom: 20px;
  padding-bottom: 20px; }
  .collection_holder > .form-group:not(:last-child) {
    border-bottom: 1px solid #ebedf2; }

.collection_holder fieldset > div {
  display: inline-block;
  line-height: 2.5rem;
  vertical-align: top;
  width: calc(100% - 45px); }

.collection_holder fieldset.link > button {
  float: right; }

.collection_holder fieldset .custom-file .col-form-label {
  display: none; }

.collection_holder fieldset .custom-file .custom-file-label {
  font-weight: 500; }
  .collection_holder fieldset .custom-file .custom-file-label:lang(fr)::after {
    content: 'Parcourir'; }
  .collection_holder fieldset .custom-file .custom-file-label:lang(en)::after {
    content: 'Browse'; }

.collection_holder fieldset .custom-file .custom-file-input {
  opacity: 0; }

.collection_holder fieldset > button {
  display: inline-block;
  line-height: 1.8rem;
  vertical-align: top; }

.collection_holder .img-thumbnail {
  display: inline-block; }
  .collection_holder .img-thumbnail ~ input[type='file'] {
    display: inline-block;
    width: auto; }

.custom-file .invalid-feedback {
  margin-top: 2rem; }

.custom-file .user-form .custom-file-input {
  cursor: pointer;
  position: absolute;
  top: -25px; }

.custom-file .custom-file-label {
  cursor: pointer; }

.m-aside-left--skin-at {
  background-color: #868e96; }
  .m-aside-left--skin-at .m-menu__nav > .m-menu__item {
    padding: 0 10px; }
    .m-aside-left--skin-at .m-menu__nav > .m-menu__item > .m-menu__link {
      border-radius: 4px; }
    .m-aside-left--skin-at .m-menu__nav > .m-menu__item > .m-menu__link > .m-menu__link-text {
      color: #fff; }
    .m-aside-left--skin-at .m-menu__nav > .m-menu__item > .m-menu__link > .m-menu__link-icon {
      color: #fff; }
    .m-aside-left--skin-at .m-menu__nav > .m-menu__item.m-menu__item--active > .m-menu__link .m-menu__link-text, .m-aside-left--skin-at .m-menu__nav > .m-menu__item:hover > .m-menu__link .m-menu__link-text {
      color: #bd0b0b !important; }
    .m-aside-left--skin-at .m-menu__nav > .m-menu__item.m-menu__item--active > .m-menu__link .m-menu__link-icon, .m-aside-left--skin-at .m-menu__nav > .m-menu__item:hover > .m-menu__link .m-menu__link-icon {
      color: #bd0b0b; }
    .m-aside-left--skin-at .m-menu__nav > .m-menu__item.m-menu__item--active > .m-menu__link, .m-aside-left--skin-at .m-menu__nav > .m-menu__item:hover > .m-menu__link {
      background: #ebedf2; }

.m-aside-menu.m-aside-menu--dropdown.m-aside-menu--submenu-skin-at .m-menu__nav .m-menu__item.m-menu__item--hover > .m-menu__submenu .m-menu__subnav {
  background-color: #868e96; }
  .m-aside-menu.m-aside-menu--dropdown.m-aside-menu--submenu-skin-at .m-menu__nav .m-menu__item.m-menu__item--hover > .m-menu__submenu .m-menu__subnav > .m-menu__item {
    padding: 0 5px; }
    .m-aside-menu.m-aside-menu--dropdown.m-aside-menu--submenu-skin-at .m-menu__nav .m-menu__item.m-menu__item--hover > .m-menu__submenu .m-menu__subnav > .m-menu__item > .m-menu__link {
      border-radius: 4px; }
    .m-aside-menu.m-aside-menu--dropdown.m-aside-menu--submenu-skin-at .m-menu__nav .m-menu__item.m-menu__item--hover > .m-menu__submenu .m-menu__subnav > .m-menu__item > .m-menu__link > .m-menu__link-text {
      color: #fff; }
    .m-aside-menu.m-aside-menu--dropdown.m-aside-menu--submenu-skin-at .m-menu__nav .m-menu__item.m-menu__item--hover > .m-menu__submenu .m-menu__subnav > .m-menu__item > .m-menu__link > .m-menu__link-icon {
      color: #fff; }
    .m-aside-menu.m-aside-menu--dropdown.m-aside-menu--submenu-skin-at .m-menu__nav .m-menu__item.m-menu__item--hover > .m-menu__submenu .m-menu__subnav > .m-menu__item.m-menu__item--active > .m-menu__link, .m-aside-menu.m-aside-menu--dropdown.m-aside-menu--submenu-skin-at .m-menu__nav .m-menu__item.m-menu__item--hover > .m-menu__submenu .m-menu__subnav > .m-menu__item:hover > .m-menu__link {
      background: #ebedf2; }
    .m-aside-menu.m-aside-menu--dropdown.m-aside-menu--submenu-skin-at .m-menu__nav .m-menu__item.m-menu__item--hover > .m-menu__submenu .m-menu__subnav > .m-menu__item.m-menu__item--active > .m-menu__link .m-menu__link-text, .m-aside-menu.m-aside-menu--dropdown.m-aside-menu--submenu-skin-at .m-menu__nav .m-menu__item.m-menu__item--hover > .m-menu__submenu .m-menu__subnav > .m-menu__item:hover > .m-menu__link .m-menu__link-text {
      color: #bd0b0b !important; }
    .m-aside-menu.m-aside-menu--dropdown.m-aside-menu--submenu-skin-at .m-menu__nav .m-menu__item.m-menu__item--hover > .m-menu__submenu .m-menu__subnav > .m-menu__item.m-menu__item--active > .m-menu__link .m-menu__link-icon, .m-aside-menu.m-aside-menu--dropdown.m-aside-menu--submenu-skin-at .m-menu__nav .m-menu__item.m-menu__item--hover > .m-menu__submenu .m-menu__subnav > .m-menu__item:hover > .m-menu__link .m-menu__link-icon {
      color: #bd0b0b; }

.m-aside-menu.m-aside-menu--dropdown.m-aside-menu--submenu-skin-at .m-menu__nav .m-menu__item.m-menu__item--hover > .m-menu__submenu .m-menu__arrow {
  color: #868e96; }

.at-user-profile-portlet {
  overflow: hidden; }

.at-avatar-portlet {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center; }
  .at-avatar-portlet > h3 {
    white-space: nowrap; }

.at-avatar-holder {
  background-color: #fff;
  border: solid 1px rgba(134, 142, 150, 0.23);
  border-radius: 49px;
  display: inline-block;
  height: 98px;
  margin-bottom: 16px;
  padding: 9px;
  position: relative;
  width: 98px; }
  .at-avatar-holder--small {
    height: 46px;
    margin: 0;
    padding: 2px;
    width: 46px; }
  .at-avatar-holder .at-avatar-image {
    background-position: center center;
    background-size: cover;
    border-radius: 100%;
    height: 100%;
    width: 100%; }

.pdf-header .at-avatar-holder {
  margin: 0; }

@media (max-width: 992px) {
  .m-menu__link .at-menu-avatar {
    display: table-cell;
    width: 35px; }
    .m-menu__link .at-menu-avatar .at-avatar-holder {
      height: 26px;
      padding: 1px;
      width: 26px; } }

.global-stats .stats-wrapper .m_height {
  max-height: 100px; }

.global-stats .stats-wrapper .stats {
  background-color: #fff; }
  .global-stats .stats-wrapper .stats:nth-child(2n) {
    background-color: #e4e4e4; }

.global-stats .stats-wrapper .chart-wrapper,
.global-stats .stats-wrapper .count-chart {
  margin-bottom: 15px;
  position: relative;
  width: 100%; }

.global-stats .stats-wrapper .count-chart::after {
  background-color: #fff;
  border-radius: 50vw;
  content: '';
  display: block;
  padding-bottom: 100%; }

.global-stats .stats-wrapper .count-chart span {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.5rem;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  width: 100%; }

.global-stats .stats-wrapper table {
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0 auto; }
  .global-stats .stats-wrapper table tr:first-child td {
    border-top: 0; }
  .global-stats .stats-wrapper table td {
    border-top: 1px solid #cacaca;
    padding: 0.25em; }
  .global-stats .stats-wrapper table .label {
    border-right: 1px solid #cacaca;
    font-size: 0.625rem;
    text-align: right;
    white-space: nowrap; }
    .global-stats .stats-wrapper table .label .legend {
      border-radius: 2px;
      display: inline-block;
      height: 1em;
      margin-left: 1em;
      vertical-align: middle;
      width: 1em; }

.last-login-bar {
  height: 30px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .last-login-bar span {
    height: 100%;
    color: #fff;
    font-weight: bold;
    padding-left: 10px;
    padding-top: 4px; }
  .last-login-bar .thirty-days-less-last-logins {
    border-radius: 4px 0 0 4px;
    background-color: #00bfff; }
  .last-login-bar .between-thirty-and-ninety-days-last-logins {
    background-color: #ff6699; }
  .last-login-bar .older-ninety-days-last-logins {
    border-radius: 0 4px 4px 0;
    background-color: #ff9966; }

.legend {
  display: block;
  font-size: 12px; }
  .legend .thirty-days-less-last-logins {
    color: #00bfff; }
  .legend .between-thirty-and-ninety-days-last-logins {
    color: #ff6699; }
  .legend .older-ninety-days-last-logins {
    color: #ff9966; }

.wrapper-carousel {
  margin-bottom: 24px; }
  .wrapper-carousel .carousel-controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 16px; }
    .wrapper-carousel .carousel-controls .carousel-prev {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: start; }
    .wrapper-carousel .carousel-controls .carousel-dots {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin: auto; }
      .wrapper-carousel .carousel-controls .carousel-dots .carousel-dot {
        padding: 0 8px; }
    .wrapper-carousel .carousel-controls .carousel-next {
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: end; }
    .wrapper-carousel .carousel-controls .la-dot-circle-o {
      color: #bd0b0b; }
    .wrapper-carousel .carousel-controls .la-circle {
      color: #ebedf2; }

div.content-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  color: #575962; }
  div.content-grid div.recap, div.content-grid div.head-content {
    border-bottom: 1px solid #e9ecef; }
  div.content-grid div.left div.head-content a, div.content-grid div.right div.head-content a {
    text-decoration: underline; }
  div.content-grid div.left {
    padding-right: 2.5rem;
    position: relative;
    grid-area: 1 / 1 / 2 / 2; }
    div.content-grid div.left::after {
      content: "";
      border-left: 1px solid #e9ecef;
      height: 100%;
      width: 1px;
      position: absolute;
      top: 0;
      right: 0; }
  div.content-grid div.right {
    padding-left: 2rem;
    grid-area: 1 / 2/ 2 / 3; }
    div.content-grid div.right a.credits {
      text-decoration: none !important;
      margin-top: 2.5rem;
      border: 1px solid;
      color: #575962;
      cursor: default; }
  div.content-grid div.head-content {
    min-height: 250px; }

.validation-btn a:hover {
  color: white; }

span.underligne {
  text-decoration: underline; }

.table thead tr.row-header {
  background: #f4f3f8; }
  .table thead tr.row-header th {
    padding: 1.5rem 1rem; }
